import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { canvasPreview, centerAspectCrop } from './PictureManager.utils';
import Button, { SecondaryButton } from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import css from './PictureManager.module.css';

const ASPECT_RATIO = 1 / 1;
const MIN_HEIGHT = 100;

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => fn.apply(undefined, deps), waitTime);

    return () => clearTimeout(t);
  }, deps);
}

const PictureManager = props => {
  const { onImageSubmit, label, labelText, error, inputProps, rootClassName } = props;

  const [imgSrc, setImgSrc] = useState();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('react-image-crop/dist/ReactCrop.css');
    }
  }, []);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, ASPECT_RATIO));
  }

  async function onSave() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    const blob = await offscreen.convertToBlob({ type: 'image/png' });

    const fileName = `cropped-image-${Date.now()}.png`;
    const file = new File([blob], fileName, { type: blob.type });

    onImageSubmit(file);
    setImgSrc(null);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div className={classNames(rootClassName || css.root, { [css.rootExtended]: !!imgSrc })}>
      {!!imgSrc ? (
        <div>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={setCompletedCrop}
            aspect={ASPECT_RATIO}
            minHeight={MIN_HEIGHT}
          >
            <img className={css.img} ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
          <div className={css.imageButtons}>
            <Button type="button" onClick={onSave} disabled={!completedCrop}>
              <FormattedMessage id="General.save" />
            </Button>
            <SecondaryButton type="button" onClick={() => setImgSrc(null)}>
              <FormattedMessage id="General.cancel" />
            </SecondaryButton>
          </div>
        </div>
      ) : (
        <>
          {label ? (
            label
          ) : (
            <label className={css.label} htmlFor={inputProps.id}>
              {labelText}
            </label>
          )}
          <input className={css.uploadAvatarInput} {...inputProps} onChange={onSelectFile} />
        </>
      )}
      <canvas
        ref={previewCanvasRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          width: completedCrop?.width,
          height: completedCrop?.height,
        }}
      />
      {error}
    </div>
  );
};

export default PictureManager;
